
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiURL from './Config';

const RatesComponent = () => {
  const [rates, setRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Fetch current rates from the server
    axios.get(`${apiURL }/api/rates`)
      .then(response => {
        setRates(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching rates:', error);
        setLoading(false);
      });
  }, []);

  const handleChange = (region, key, value) => {
    setRates(prevRates => ({
      ...prevRates,
      [region]: {
        ...prevRates[region],
        [key]: parseFloat(value)
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${apiURL }/api/rates`, rates, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setMessage(response.data.message);
        alert('Rates updated successfully!');  // Alert after successful update
      })
      .catch(error => {
        console.error('Error updating rates:', error);
        setMessage('Error updating rates');
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='main-content'>
      <div className='rates'>
      <h2>Update Shipping Rates</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        {Object.keys(rates).map(region => (
          <div key={region} style={{ marginBottom: '20px' }}>
            <h3>{region}</h3><br/>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px' }}>Up to 250g:</label>
                <input
                  type="number"
                  value={rates[region].amount250}
                  onChange={(e) => handleChange(region, 'amount250', e.target.value)}
                  style={{ padding: '5px', width: '100px' }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px' }}>Up to 1000g:</label>
                <input
                  type="number"
                  value={rates[region].amount1000}
                  onChange={(e) => handleChange(region, 'amount1000', e.target.value)}
                  style={{ padding: '5px', width: '100px' }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px' }}>Additional per 500g:</label>
                <input
                  type="number"
                  value={rates[region].additional500}
                  onChange={(e) => handleChange(region, 'additional500', e.target.value)}
                  style={{ padding: '5px', width: '100px' }}
                />
              </div>
            </div>
          </div>
        ))}
        <button type="submit">Update Rates</button>
      </form>
    </div>
    </div>
  );
};

export default RatesComponent;