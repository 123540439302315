import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiURL from './Config';

const CompanyForm = () => {
  const [formData, setFormData] = useState({
    id: null,
    companyName: '',
    address: '',
    mobile1: '',
    mobile2: '',
    panNumber: '',
    gstNumber: '',
    logoName: '', // Added logoName
    logo: null,
  });
  const [companyList, setCompanyList] = useState([]);

  // Fetch the company list from the server
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${apiURL }/api/companies/all`);
        setCompanyList(response.data);
      } catch (error) {
        console.error('Error fetching companies', error);
      }
    };
    fetchCompanies();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('companyName', formData.companyName);
    form.append('address', formData.address);
    form.append('mobile1', formData.mobile1);
    form.append('mobile2', formData.mobile2);
    form.append('panNumber', formData.panNumber);
    form.append('gstNumber', formData.gstNumber);
    form.append('logoName', formData.logoName); // Append logoName
    if (formData.logo) {
      form.append('logo', formData.logo);
    }

    try {
      if (formData.id) {
        const response = await axios.put(`${apiURL }/api/companies/${formData.id}`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        alert(response.data.message || 'Company updated successfully');
      } else {
        const response = await axios.post(`${apiURL }/companies`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        alert(response.data.message || 'Company data saved successfully');
      }

      const updatedResponse = await axios.get(`${apiURL }/api/companies/all`);
      setCompanyList(updatedResponse.data);

      setFormData({
        id: null,
        companyName: '',
        address: '',
        mobile1: '',
        mobile2: '',
        panNumber: '',
        gstNumber: '',
        logoName: '', // Reset logoName
        logo: null,
      });
    } catch (error) {
      console.error(error);
      alert('Error saving company data');
    }
  };

  const handleEdit = (company) => {
    setFormData({
      id: company.id,
      companyName: company.companyName,
      address: company.address,
      mobile1: company.mobile1,
      mobile2: company.mobile2,
      panNumber: company.panNumber,
      gstNumber: company.gstNumber,
      logoName: company.logoName || '', // Set logoName for editing
      logo: null,
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this company?')) {
      try {
        const response = await axios.delete(`${apiURL }/api/companies/${id}`);
        alert(response.data.message || 'Company deleted successfully');
        const updatedResponse = await axios.get(`${apiURL }/api/companies/all`);
        setCompanyList(updatedResponse.data);
      } catch (error) {
        console.error(error);
        alert('Error deleting company');
      }
    }
  };

  return (
    <div className="main-content">
      <div className='company'>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="mobile1"
            placeholder="Mobile Number 1"
            value={formData.mobile1}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="mobile2"
            placeholder="Mobile Number 2"
            value={formData.mobile2}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="panNumber"
            placeholder="PAN Number"
            value={formData.panNumber}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="gstNumber"
            placeholder="GST Number"
            value={formData.gstNumber}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="logoName" // Added logoName input
            placeholder="Logo Name"
            value={formData.logoName}
            onChange={handleInputChange}
            required
          />
          <input type="file" name="logo" onChange={handleFileChange} />
          <button type="submit">{formData.id ? 'Update Company' : 'Save Company'}</button>
        </form>
      </div>

      {/* Table to display company data */}
      <div>
        <h2>Company List</h2>
        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Address</th>
              <th>Mobile 1</th>
              <th>Mobile 2</th>
              <th>PAN Number</th>
              <th>GST Number</th>
              <th>Logo Name</th> {/* Updated header for logo name */}
              <th>Logo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companyList.map((company) => (
              <tr style={{ width: '100px', height: '100px' }} key={company.id}>
                <td>{company.companyName}</td>
                <td>{company.address}</td>
                <td>{company.mobile1}</td>
                <td>{company.mobile2}</td>
                <td>{company.panNumber}</td>
                <td>{company.gstNumber}</td>
                <td>{company.logoName}</td> {/* Display logo name */}
                <td>
                  {company.logoUrl && (
                    <img
                      src={company.logoUrl}
                      alt="Logo"
                      style={{ width: '100px', height: '100px' }}
                    />
                  )}
                </td>
                <td>
                  <button onClick={() => handleEdit(company)}>Edit</button>
                  <button onClick={() => handleDelete(company.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanyForm;
