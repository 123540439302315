import React, { useState, useEffect } from "react";
import Barcode from "react-barcode";

const BarcodePrinter = () => {
  const [barcodes, setBarcodes] = useState([]);

  useEffect(() => {
    // Add @media print styles dynamically to the document head
    const style = document.createElement("style");
    style.textContent = `
      @media print {
        .print-hidden {
          display: none !important;
        }
        body {
          margin: 0;
          padding: 0;
        }
        .print-content {
          display: flex;
          flex-direction: column; /* Arrange barcodes in a column */
          align-items: center; /* Center horizontally */
          margin: 0 auto; /* Center container on the page */
          padding: 0;
        }
        .barcode-container {
          page-break-inside: avoid;
          display: flex;
          justify-content: center; /* Center barcode within the container */
        }
        /* Hide everything except the barcodes */
        * {
          visibility: hidden;
        }
        .print-content, .print-content * {
          visibility: visible;
        }
        .print-content {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); /* Perfect centering horizontally */
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      // Clean up the style element when the component unmounts
      document.head.removeChild(style);
    };
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const lines = event.target.result.split("\n");
      setBarcodes(lines.filter((line) => line.trim() !== ""));
    };
    reader.readAsText(file);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="main-content">
      <h2 className="print-hidden">Barcode Sticker Generator</h2>
      <input
        type="file"
        accept=".csv, .txt"
        onChange={handleFileUpload}
        className="print-hidden"
      />
      <button
        className="print-hidden"
        onClick={handlePrint}
        style={{ marginTop: "20px" }}
      >
        Print Barcodes
      </button>
      <div
        className="print-content"
        style={{
          marginTop: "0px",
          display: "flex",
          flexDirection: "column", // Each barcode on a new row
          alignItems: "center", // Center all barcodes
        }}
      >
        {barcodes.map((code, index) => (
          <div
            key={index}
            className="barcode-container"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center", // Center the barcode in the row
              alignItems: "center",
              marginTop: index === 0 ? "0mm" : "10mm", // No gap above the first barcode
            }}
          >
            <Barcode
              value={code}
              width={1.2} // Adjust barcode thickness
              height={50} // Adjust barcode height
              fontSize={16} // Font size for text below barcode
              format="CODE128" // Ensure barcode format is CODE128
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarcodePrinter;
